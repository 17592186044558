import Script from 'next/script';
import {useSelector} from 'react-redux';
import {useIsLargeDevice} from '@jobseeker/lotus/lib/hooks';
import {Box} from '@jobseeker/lotus/lib/foundations';
import {COOKIE_CKT} from '@constants';

import type {AppState} from '@jobseeker/common-core/lib/types';
import {useEffect, useState} from 'react';

export const Chat = () => {
  const isAboveLg = useIsLargeDevice('lg');
  const isAboveXL = useIsLargeDevice(1442);
  const [chatRight, setChatRight] = useState(0);

  const {
    userId,
    authInfo: {token},
  } = useSelector((state: AppState) => state.auth);

  const calculateChatRight = () => {
    const adsBanner = document.getElementById(
      'job_ads_banner_featured-institute'
    );
    const boundingBox = adsBanner?.getBoundingClientRect();
    if (boundingBox) {
      const windowWidth = window.innerWidth;
      const offsetRight = windowWidth - boundingBox?.right;
      setChatRight(offsetRight);
    }
  };

  useEffect(() => {
    calculateChatRight();
    window.addEventListener('resize', calculateChatRight);
    return () => {
      window.removeEventListener('resize', calculateChatRight);
    };
  }, []);

  const notify = (chatCount: string) => {
    console.log('Notify', chatCount);
  };
  return (
    <>
      {userId && token && (
        <>
          <Box id="sticky-chat" display={{xxs: 'none', md: 'block'}} />

          <Script
            id={`${process.env.NEXT_PUBLIC_APP_NAME}-recruiter-chat`}
            dangerouslySetInnerHTML={{
              __html: `
            chatObject.stickyChat({
              theme: 'seeker-theme-one',
              userId: ${userId},
              userType: 'j',
              token: '${token}',
              tokenName:'${process.env.NEXT_PUBLIC_APP_NAME?.toUpperCase()}${COOKIE_CKT}',
              position: 'sticky',           //For jobseeker sticky chat
              offsetRight: ${isAboveXL ? chatRight : isAboveLg ? chatRight : 0},
              notifyMessage: ${notify},
        })
          `,
            }}
          />
        </>
      )}
    </>
  );
};

export default Chat;

/**
 * @returns Chat widget component shown on /chats page
 */
export const Chats = () => {
  const {
    userId,
    authInfo: {token},
  } = useSelector((state: AppState) => state.auth);

  const {profile} = useSelector((state: AppState) => state.user);

  return (
    <>
      {userId && token && profile?.name && (
        <>
          <Box id="my-chat" />
          <Script
            id={`${process.env.NEXT_PUBLIC_APP_NAME}-recruiter-chats`}
            dangerouslySetInnerHTML={{
              __html: `
                chatObject.myChat({
                  userId: ${userId},
                  theme: 'seeker-theme-one',
                  userType: 'j',
                  token: '${token}',
                  name: '${profile.name}',
                  tokenName:'${process.env.NEXT_PUBLIC_APP_NAME?.toUpperCase()}${COOKIE_CKT}',
                })
              `,
            }}
          />
        </>
      )}
    </>
  );
};
