import NotFound from './NotFound';
import InjectSearchOverlay from './SearchInjection';
import Chat from './Chat';
import Home from './Home';
import HTMLSitemap from './Sitemap';
import CourseDetailComponent from './Course/CourseDetail';

export {
  Home,
  NotFound,
  InjectSearchOverlay,
  Chat,
  HTMLSitemap,
  CourseDetailComponent,
};
