import {ResponsiveLayout, NavLayout} from '@layouts';
import {COURSE} from '@constants';
import {VersionVariants} from '@jobseeker/lotus/lib/enums/variants';
import {shallowEqual, useSelector} from 'react-redux';
import {SeoIntrup} from '@layouts/SeoLayout';
import {isCSR} from '@jobseeker/lotus/lib/utils';
import {InjectSearchOverlay} from '@components';
import dynamic from 'next/dynamic';
import {AppState, Course} from '@jobseeker/common-core/lib/types';
import {useRouter} from 'next/router';
import Dialog from '@jobseeker/lotus/lib/components/Dialog';
import {Loader} from '@jobseeker/lotus/lib/components';
import {useState} from 'react';

const CourseDetailHarness = dynamic(
  () =>
    import(
      '@jobseeker/client-harness/lib/components/CourseHarness/CourseDetail'
    ),
  {
    loading: () => <></>,
    ssr: false,
  }
);

const LoginForm = dynamic(
  () =>
    import(
      '@jobseeker/client-harness/lib/components/AuthenticationHarness/AuthForm'
    ),
  {
    loading: () => <Loader />,
    ssr: false,
  }
);

type CourseDetailProps = {
  coursePublishedId: number;
  courseDetail: Course;
  userId: number;
  token: string;
};

const CourseDetailComponent = ({courseDetail}: CourseDetailProps) => {
  const [loginOpen, setLoginOpen] = useState(false);

  const {
    app: {
      layout: {searchOverlay: isSearchEnabled},
    },
  } = useSelector((state: AppState) => state, shallowEqual);

  const onLoginSuccess = () => {
    if (isCSR()) {
      setTimeout(() => window.location.reload(), 600);
    }
  };

  const handleEnquiryInLoggedOutState = () => {
    setLoginOpen(true);
  };

  const breadcrumbElements = [
    {
      name: 'Home',
      item: '/',
    },
    {
      name: 'Courses',
      item: '/course',
    },
    {
      name: courseDetail?.title ?? 'Course removed',
      item: '',
    },
  ];

  const router = useRouter();
  const {refinv = 'NA', reforg = 'NA'} = router.query;

  return (
    <NavLayout page={COURSE}>
      <ResponsiveLayout
        breadcrumbElements={!!courseDetail?.id ? breadcrumbElements : []}
        variant={VersionVariants.VERSION_TWO}
      >
        <InjectSearchOverlay isSearchEnabled={isSearchEnabled} />
        {isCSR() && (
          <SeoIntrup
            title={`${courseDetail?.title ?? 'Course not found'} | IIMJobs`}
            description={`${
              courseDetail?.title ?? 'Courses'
            } for working professionals and entrepreneurs to learn & develop advanced leadership & management skills.`}
            pageNo={0}
            nextPage={false}
            type={COURSE}
          />
        )}
        {!!courseDetail?.id && (
          <CourseDetailHarness
            refinv={refinv.toString() ?? 'NA'}
            reforg={reforg.toString() ?? 'NA'}
            {...courseDetail}
            handleEnquiryInLoggedOutState={handleEnquiryInLoggedOutState}
          />
        )}
        {!courseDetail?.id && (
          <>
            This course has been either closed or removed by the administrator.
          </>
        )}
        {loginOpen && (
          <Dialog
            title="Login to your account"
            subtitle="Welcome back! 👋"
            invertedTitle
            variant={VersionVariants.VERSION_TWO}
            open={loginOpen}
            onClose={() => setLoginOpen(false)}
          >
            <LoginForm
              onSuccess={onLoginSuccess}
              variant={VersionVariants.VERSION_TWO}
            />
          </Dialog>
        )}
      </ResponsiveLayout>
    </NavLayout>
  );
};

export default CourseDetailComponent;
