import {Link, Paragraph} from '@jobseeker/lotus/lib/elements';
import {Typography} from '@mui/material';

const alphabetsArray: string[] = [];
for (let i = 65; i <= 90; ++i) {
  alphabetsArray.push(String.fromCharCode(i));
}

const CharactersList = ({text = 'Job Title'}) => {
  const baseUrl = process.env.NEXT_PUBLIC_LOAD_CONFIG;

  return (
    <Paragraph
      title=""
      body={
        <>
          <Typography component="span" sx={{fontWeight: 700, mb: 12.5}}>
            {text}
          </Typography>
          {alphabetsArray.map(char => {
            const href = new URL(
              `/sitemap/${
                text === 'Recruiter' ? 'r/recruiter' : 't/job'
              }-title-${char.toLowerCase()}`,
              baseUrl
            ).href;
            return (
              <Link key={char} href={href} target="_blank">
                {char}
              </Link>
            );
          })}
        </>
      }
    />
  );
};

export default CharactersList;
