import {Grid} from '@jobseeker/lotus/lib/foundations';
import CharactersList from './CharactersList';
import {Typography} from '@mui/material';
import {Sitemap} from '../types';
import {Link} from '@jobseeker/lotus/lib/elements';

const JobCategories = ({categories}: Sitemap) => {
  return (
    <>
      <Typography component="h2" mb={1}>
        Job Categories
      </Typography>
      <CharactersList />
      <Grid container spacing={2}>
        {categories.map(({label, dataId}) => (
          <Grid item key={label} xs={4}>
            <Link href={`sitemap/c/${dataId}`} target="_blank">
              {label}
            </Link>
          </Grid>
        ))}
      </Grid>
    </>
  );
};

export default JobCategories;
