import {MAJOR_CITIES} from '@constants';
import {Link} from '@jobseeker/lotus/lib/elements';
import {Grid} from '@jobseeker/lotus/lib/foundations';
import {cleanUrl} from '@jobseeker/lotus/lib/utils';
import {Typography} from '@mui/material';
import {capitalizeFirstLetter} from '@utils';

const CityPages = () => {
  return (
    <>
      <Typography component="h2" mt={2} mb={1}>
        City pages
      </Typography>
      <Grid container spacing={2}>
        {MAJOR_CITIES.map(city => (
          <Grid key={city} item xs={4}>
            <Link href={`sitemap/city/${cleanUrl(city)}`} target="_blank">
              {capitalizeFirstLetter(city)}
            </Link>
          </Grid>
        ))}
        <Grid key={'other-cities'} item xs={4}>
          <Link href={'sitemap/city/other-cities'} target="_blank">
            Other cities
          </Link>
        </Grid>
      </Grid>
    </>
  );
};

export default CityPages;
