import {Typography} from '@mui/material';
import CharactersList from '../JobCategories/CharactersList';

const Recruiters = () => {
  const alphabetsArray: string[] = [];
  for (let i = 65; i <= 90; ++i) {
    alphabetsArray.push(String.fromCharCode(i));
  }

  return (
    <>
      <Typography component="h2" mb={1} mt={2}>
        Recruiters
      </Typography>
      <CharactersList text="Recruiter" />
    </>
  );
};

export default Recruiters;
