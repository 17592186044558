import dynamic from 'next/dynamic';
import {Box, Grid} from '@jobseeker/lotus/lib/foundations';
import Typography from '@mui/material/Typography';
import Link from '@jobseeker/lotus/lib/elements/Link';
import {useIsLargeDevice} from '@jobseeker/lotus/lib/hooks';
import useInterceptor from '@jobseeker/common-core/lib/hooks/useInterceptor';
import Image from 'next/image';

const Button = dynamic(() => import('@jobseeker/lotus/lib/elements/Button'));

const NotFound = () => {
  const isAboveMobile = useIsLargeDevice('sm');

  const interceptor: /* eslint-disable @typescript-eslint/no-explicit-any */ any =
    useInterceptor();
  const {alternativeJobs, heading} = interceptor;

  return (
    <Grid container justifyContent={'center'}>
      <Grid
        item
        minWidth="240px"
        textAlign={'center'}
        paddingTop="65px"
        height={'623px'}
      >
        <Typography variant="h5" fontWeight={700} margin={'24px 0'}>
          {heading}
        </Typography>

        <Typography component="p" margin={'24px 0'}>
          {'Did you mean:'}
          {alternativeJobs?.map((link: any, index: number) => (
            <Link
              key={`notfound-alternativejobs-${index}`}
              href={link['url']}
              sx={{
                padding: '4px',
                fontSize: '16px',
                fontColor: 'secondary.main',
                '&:nth-of-type(4n)': {
                  fontWeight: 700,
                },
              }}
            >
              {link['title']}
            </Link>
          ))}
        </Typography>

        <Box>
          <Image
            src="https://job-static.hirist.com/V2/static/media/notfound.978947b0.svg"
            width={isAboveMobile ? 465 : 265}
            height={isAboveMobile ? 295 : 240}
            alt="Page Not Found"
            style={{
              maxWidth: '465px',
            }}
          />
        </Box>
        <Button
          variant="outlined"
          href="/"
          sx={{
            margin: '40px 0 0 0',
            fontWeight: 600,
            border: '1.5px solid',
          }}
        >
          {'Go Home'}
        </Button>
      </Grid>
    </Grid>
  );
};

export default NotFound;
