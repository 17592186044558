import JobCategories from './JobCategories';
import Recruiters from './Recruiters';
import CityPages from './CityPages';
import {Sitemap} from './types';

const HTMLSitemap = ({categories}: Sitemap) => {
  return (
    <>
      <JobCategories categories={categories} />
      <Recruiters />
      <CityPages />
    </>
  );
};

export default HTMLSitemap;
